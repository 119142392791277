import { useRecoilState } from "recoil";
import { coursDetailsIdState, mainViewState } from "../../../atoms/AppAtome";
import { APP_URL } from "../../../URL";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { ExclamationCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Modal, Spin } from "antd";
import { TinyColor } from '@ctrl/tinycolor';

const colors3 = ['#40e495', '#30dd8a', '#2bb673'];

const getHoverColors = (colors) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());
const getActiveColors = (colors) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());

const { confirm } = Modal;
const showConfirm = () => {
  confirm({
    title: 'Systeme de paiement automatique en maintenance',
    icon: <ExclamationCircleFilled />,
    content: <>
            <p>
            Nos équipes techniques sont en train de finaliser l'intégration de la fonctionnalité de paiement à la plateforme. Pour acheter ce cours, vous devez nous contacter sur WhatsApp pour finaliser le paiement. <br /><br />
                <h5>Cliquez sur le bouton OK ci-dessous pour ouvrir la conversation</h5>
            </p>

    </>,
    // footer:null,
    onOk() {
      console.log('OK');
      window.location.href = 'https://wa.me/237680950319'
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};


export default function Paiement(){

    const [coursDetailId, setCoursDetailId] = useRecoilState(coursDetailsIdState)
    const [cours, setCours] = useState([]);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(true);
    const [mainView, setMainView] = useRecoilState(mainViewState);
   
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    useEffect(() => {
        axios.get(APP_URL+'students/cours-detail/'+coursDetailId,config).then((response) => {
            setCours(response.data);

            setLoading(false);
            console.log(response.data);

        }).catch((error) => {
            console.log(error); // erreir
        } )

    },[]); 

    return (
        <>
          {loading == true? <>

<center>
 <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 30,
        }}
        spin
      />
    }
  />
</center>

    </>:<>
        {cours.datas.mode === 'Payant'? <>
          <center>

    <br /><br />

      <h1> <code style={{color:'green'}}>{cours.datas.prix} xaf</code></h1>
      <br />
      <Button type="primary" onClick={showConfirm} className="btn btn-success" style={{width:'50%'}} size="smal">
          
          Acheter maintenant

        <br />
      </Button>

    <br /><br />

          </center>
        </>:<>
           <center>

   <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: `linear-gradient(116deg,  ${colors3.join(', ')})`,
            colorPrimaryHover: `linear-gradient(116deg, ${getHoverColors(colors3).join(', ')})`,
            colorPrimaryActive: `linear-gradient(116deg, ${getActiveColors(colors3).join(', ')})`,
            lineWidth: 0,
          },
        },
      }}
    >

      <br /><br />
      <h1 style={{color:'green'}}> <span style={{color:'green'}}>
        C'est gratuit  
      </span></h1>
      <Button type="primary" onClick={() => setMainView('process_access_free')} className="btn btn-success" style={{width:'50%'}} size="smal">
        Suivre maintenant
      </Button>

      <br /><br />

    </ConfigProvider>

           </center>
        </>}

    </>}

        </>
    );
}