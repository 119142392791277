import {Tooltip,Button, ConfigProvider} from 'antd';
import { FullscreenExitOutlined,FormOutlined,AlignLeftOutlined, FullscreenOutlined,ExclamationCircleFilled,CheckCircleOutlined,LoadingOutlined } from "@ant-design/icons";
import CreateModule from './CreateModule';
import { useRecoilState } from 'recoil';
import { coursIdState, subViewState } from '../../../../atoms/AppAtome';
import CreatePromos from './CreatePromos';
import ListePromos from './ListePromos';

export default function PromosList({cours}){

    const [subView, setSubview] = useRecoilState(subViewState);
    const [coursId, setCoursId] = useRecoilState(coursIdState);

    return (
        <>
           <h5>Promotion pour : "{cours.nom}"</h5>
           <hr />
        <Tooltip placement="top" title="Creer une nouvelle promotion">
          <Button className='btn btn-primary' onClick={() => setSubview('create_promos')} style={{width:'10%'}} ><FormOutlined /></Button>
        </Tooltip>
        &nbsp; &nbsp;
        <Tooltip placement="top" title="Liste des promos">
          <Button className='btn btn-primary' onClick={() => setSubview('liste_promos')} style={{width:'10%'}} ><AlignLeftOutlined /></Button>
        </Tooltip>
        <hr />

        {subView === 'create_promos' && <CreatePromos cours={cours} /> }
        {subView === 'liste_promos' && <ListePromos cours={cours} />}

        </>
    );
}