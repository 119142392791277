import { InboxOutlined } from '@ant-design/icons';
import { message, Upload,notification } from 'antd';
import { useRecoilState } from 'recoil';
import { coursIdState, mainViewState } from '../../../atoms/AppAtome';
import { APP_URL } from '../../../URL';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PlusOutlined } from "@ant-design/icons";
import { CheckCircleOutlined } from '@ant-design/icons';


const { Dragger } = Upload;

const getBase64 = (file) => 
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });



export default function AddCoverCourse() {
  let [coursId, setCoursId] = useRecoilState(coursIdState);
  const [mainView, setMainView] = useRecoilState(mainViewState);
  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
  const [fileList, setFileList] = useState([]);
  const [selected, setSelected] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [imageHeigt, setImageHeigt] = useState(0);
  const [imageWidth,setImageWidth] = useState(0);


  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${cookies.seduc_token}`
    };
  
  const config = {
    headers: headers,
    };




  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  useEffect(() => {
    localStorage.setItem('height',720);
    localStorage.setItem('width',1280);
    // localStorage.setItem('try',false);
    // const canUpload = localStorage.getItem('canUpload');
    localStorage.setItem('canUpload',false);
  },[]);

  const handleChange = ({ fileList: newFileList }) => {
    
   setFileList(newFileList);
   setSelected(true);
    // Récupérez le premier fichier de la liste (vous pouvez ajuster cela selon vos besoins)
    const file = newFileList[0];
    
    if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
                console.log(`L'image a une largeur de ${img.width} pixels et une hauteur de ${img.height} pixels.`);
               
                if(parseInt(localStorage.getItem('height')) != img.height && parseInt(localStorage.getItem('width')) != img.width){
                  setFileList([]);

                  notification.open({
                      message:(
                         <div style={{ color: 'white' }}>
                           IMAGE REJETÉE. Veuillez respecter les dimensions.
                         </div>
                        ),
                      description: '',
                      placement:'bottomLeft', 
                      icon: <CheckCircleOutlined />,
                      style: {
                          backgroundColor: 'red', // Couleur de fond personnalisée
                          color:'white',
                          // Autres styles personnalisés...
                        },
                    })
                  
                }else{

                  axios.post(`${APP_URL}profs/save-cover/${coursId}`, {
                    file,
                  },config).then((response) => {
                    console.log(response.data)
                    setFileList(response.data);
    
                    notification.open({
                      message:(
                         <div style={{ color: 'white' }}>
                           Enregistrer avec success
                         </div>
                        ),
                      description: '',
                      placement:'bottomLeft', 
                      icon: <CheckCircleOutlined />,
                      style: {
                          backgroundColor: 'green', // Couleur de fond personnalisée
                          color:'white',
                          // Autres styles personnalisés...
                        },
                    })
                    
                    setMainView('course_list'); 
    
                  }).catch((error) => {
                    console.log(error.response.data);
                  });
    
                }
            };
        };

        reader.readAsDataURL(file.originFileObj);
    }
};

  
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      <div class="container-fluid">
       
        <h5>Ajoutez une photo de couverture a votre cours</h5>
        <div className='alert alert-warning'>
        Pour des raisons de performance visuelle, vous devez télécharger une image avec des dimensions spécifiques, sinon elle ne pourra pas être prise en compte.<br />
Les dimensions autorisées sont: <kbd>1280 x 720</kbd> (1280 de largeur et 720 de auteur)
        </div>
        <div className="container-fluid" style={{ background: 'white', padding: '2%' }}>
          <center>
          <Upload

            customRequest={(file) => {

            }}

            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            name="file"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>

          </center>
        </div>
      </div>
    </>
  );
}
