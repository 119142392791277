import { useRecoilState } from "recoil";
import { coursDetailsIdState, mainViewState } from "../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Col, Row, Spin } from "antd";
import axios from "axios";
import { APP_URL } from "../../../URL";
import { LoadingOutlined, ReadOutlined } from "@ant-design/icons";


export default function ReadMyCourse(){

    const [coursDetailId, setCoursDetailId] = useRecoilState(coursDetailsIdState)
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [chapitre, setChapitre] = useState([]);
    const [cours, setCours] = useState([]);

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    useEffect(() => {
        axios.get(APP_URL+'students/get-chapitre/'+coursDetailId,config).then((response) => {
            setLoading2(false);
            setChapitre(response.data.datas);
            console.log('CHAAP',response.data);
        }).catch((error) => {
            console.log(error);
        })

       
    },[]);

    useEffect(() => {

        axios.get(APP_URL+'students/get-one-course/'+coursDetailId,config).then((response) => {

            setLoading(false);
            setCours(response.data.datas)

        }).catch((error) => {

            console.log(error);
        });
        
    },[]);

    return (
        <>
    <div class="container-fluid">

    <h3><span style={{fontFamily:'verdana'}}>{cours.nom}</span></h3>
    <Row>
      <Col span={15}>


        <iframe width="560" height="315" src={`https://www.youtube.com/embed/${cours.url}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        {loading == true && <>
            <center>
            <br />
            <br />
 <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 30,
        }}
        spin
      />
    }
  />
            </center>
        </>}

        
      </Col>




      {/* ------------------------------------------------------------------------ */}

      <Col span={8}>

        <h4><span style={{fontFamily:'verdana'}}>Liste des Chapitres</span></h4>
        <hr />

        {chapitre.map((item) => (
            <>
                <h5 style={{color:'gray'}}> <ReadOutlined /> <a href="javascript:void(0)">{item.titre}</a></h5>
            </>
        ))}

{loading2 == true && <>
            <center>
            <br />
            <br />
 <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 30,
        }}
        spin
      />
    }
  />
            </center>
        </>}

        
      </Col>

    </Row>

    </div>
        </>
    );
}