import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { useRecoilState } from "recoil";
import axios from 'axios';
import {Button,notification} from 'antd';
import { coursIdState, mainViewState, openState } from "../../../../atoms/AppAtome";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from '@ant-design/icons';


export default function UpdateData({cours}){

    const [bookData, setBookData] = useState({})
     useEffect(() => {
        console.log('book data init:',cours)
       setBookData({
            nom: cours.nom,
            type:cours.type,
            description:cours.description,
            prix_initial:cours.prix_initial,
            prix_reduc:cours.prix_reduc,
            duree:cours.duree,
            niveau:cours.niveau,
            langue:cours.langue,
            extension:cours.extension,
            mode:cours.mode,
            certification:cours.certification,
            url_course:cours.intro_url
          });
    
     },[]);

      const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
      const [loading, setLoading] = useState(false);
  
        const [mainView, setMainView] = useRecoilState(mainViewState);
        const [coursId, setCourId] = useRecoilState(coursIdState);
        const [open, setOpen] = useRecoilState(openState);

      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.seduc_token}`
          };
        
        const config = {
          headers: headers,
          };

          const handleChange = (e) => {
            const { name, value } = e.target;
            setBookData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
        
          };

          const handleSubmit = (e) => {
            e.preventDefault();
            setLoading(true);

            axios.put(APP_URL+'profs/update-course/'+cours.id,bookData,config).then((response) => {
              setLoading(false);

              // notification
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setOpen(false);

            }).catch((error) => {
              console.log(error);
              setLoading(false);

              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Une erreur est survenue
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'red', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

            })

          }

    return (
        <>
             <form onSubmit={handleSubmit} style={{background:'white', padding:'2%'}}>
            <label htmlFor="titre" className="form-label">
              Titre du cours
            </label>
            <input
              type="text"
              className="form-control"
              id="nom"
              name="nom"
              value={bookData.nom}
              onChange={handleChange}
              required
            />

            <label htmlFor="titre" className="form-label">
              Address de la Video du cours
            </label>
            <input
              type="text"
              className="form-control"
              id="url_course"
              name="url_course"
              value={bookData.intro_url}
              onChange={handleChange}
              required
            />

            <label htmlFor="titre" className="form-label">
              Type de contenu du cours
            </label>
            <select className='form-control' name='type'>
                <option value={'Texte'}>Texte</option>
                <option value={'Video'}>Video</option>
                <option value={'Texte et video'}>Texte et video</option>
            </select>

            <label htmlFor="titre" className="form-label">
              Prix initial du cours
            </label>
            <input
              type="number"
              className="form-control"
              id="prix_initial"
              name="prix_initial"
              value={bookData.prix_initial}
              onChange={handleChange}
              required
            />

            <label htmlFor="titre" className="form-label">
              Prix de reduction du cours
            </label>
            <input
              type="number"
              className="form-control"
              id="prix_reduc"
              name="prix_reduc"
              value={bookData.prix_reduc}
              onChange={handleChange}
              required
            />

          
            <label htmlFor="titre" className="form-label">
              Extension de la duree du cours
            </label>
           <select className='form-control' onChange={handleChange} name='extension'>
              <option value={'min'}>min</option>
              <option value={'h'}>h</option>
              <option value={'s'}>s</option>
           </select>

           <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Mode du cours
            </label>
           <select className='form-control' onChange={handleChange} required name='mode'>
              <option value={'Gratuit'}>Gratuit</option>
              <option value={'Payant'}>Payant</option>
           </select>
          </div>
          
            <label htmlFor="titre" className="form-label">
              Niveau de contenu du cours
            </label>
            <select className='form-control'  onChange={handleChange} name='niveau'>
                <option value={'Debutant'}>Debutant</option>
                <option value={'Expert'}>Expert</option>
                <option value={'Moyen'}>Moyen</option>
            </select>

            <label htmlFor="titre" className="form-label">
              Langue du cours
            </label>
            <select className='form-control'  onChange={handleChange} name='langue'>
                <option value={'Français'}>Français</option>
                <option value={'Anglais'}>Anglais</option>
                <option value={'Autre'}>Autre</option>
            </select>

            <label htmlFor="titre" className="form-label">
              Description de la formation
            </label>
           <textarea name='description' value={bookData.description}  onChange={handleChange} className='form-control'></textarea>
           
           <label htmlFor="titre" className="form-label">
              Description du certificat de ce cette formation
            </label>
           <textarea name='certification' value={bookData.certification}  onChange={handleChange} className='form-control'></textarea>
           
           <br /><br/>
            <Button type='primary' className="btn btn-primary"  htmlType='submit' loading={loading}>Enregistre les modifications du cours</Button>
         
             </form>
        </>
    );
}