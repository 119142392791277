
import {Tooltip,Button, ConfigProvider} from 'antd';
import { FullscreenExitOutlined,FormOutlined,AlignLeftOutlined, FullscreenOutlined,ExclamationCircleFilled,CheckCircleOutlined,LoadingOutlined } from "@ant-design/icons";
import CreateModule from './CreateModule';
import { useRecoilState } from 'recoil';
import { coursIdState, subViewState } from '../../../../atoms/AppAtome';
import ListeModule from './ListeModule';
import AddChapForm from './AddChapForm';

export default function ModuleList({cours}){
    const [subView, setSubview] = useRecoilState(subViewState);
    const [coursId, setCoursId] = useRecoilState(coursIdState);

    return (
        <>
        <h5>Les modules du cours {cours.nom}</h5>
        <hr />
        <Tooltip placement="top" title="Creer un nouveau module pour ce cours">
          <Button className='btn btn-primary' onClick={() => setSubview('create_module')} style={{width:'10%'}} ><FormOutlined /></Button>
        </Tooltip>
        &nbsp; &nbsp;
        <Tooltip placement="top" title="Liste des modules du cours">
          <Button className='btn btn-primary' onClick={() => setSubview('liste_module')} style={{width:'10%'}} ><AlignLeftOutlined /></Button>
        </Tooltip>

        <hr />

            {subView === 'create_module' && <CreateModule cours={cours} /> }
            {subView === 'liste_module' && <ListeModule cours={cours} />}
            {subView === 'add_chap_form' && <AddChapForm cours={cours} />}
        </>
    );
}