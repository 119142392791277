import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { APP_URL } from "../../../../URL";
import {Spin} from 'antd';
import {LoadingOutlined,ArrowRightOutlined} from '@ant-design/icons';
import { useRecoilState } from "recoil";
import { subViewState } from "../../../../atoms/AppAtome";

export default function ListePromos({cours}){

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [subView, setSubview] = useRecoilState(subViewState);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
      };
      
      const config = {
        headers: headers,
        };


    useEffect(() => {
        axios.get(APP_URL+'profs/get-promos/'+cours.id,config).then((response) => {
            console.log('PROMOS ACTIF',response.data.datas)
            setData(response.data.datas);
        }).catch((error) => {
            console.log(error);
            // setData(error)
        })
    },[]);

    return (
        <>
            <h5>Liste des promos actif de ce cours</h5>
            {/* {data.datas.code === 'ERR_BAD_REQUEST' && <><i>Aucune promos actif pour le moment</i></>} */}
            <hr />
            <h6>{data.code} &nbsp; <div className="badge badge-primary">{data.valide?'EXPIRE':'ACTIF'}</div></h6>
        </>
    );
}