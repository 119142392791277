import { useRecoilState, useResetRecoilState } from "recoil";
import { coursDetailsIdState, mainViewState } from "../../../atoms/AppAtome";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { APP_URL } from "../../../URL";
import axios from "axios";
import img from '../../assets/432398145_356544404025701_8967787591334529753_n.jpg'
import { Spin,Avatar, Card } from "antd";
import { EditOutlined, EllipsisOutlined, SettingOutlined,LoadingOutlined, SendOutlined } from '@ant-design/icons';
const { Meta } = Card;

export default function CoursDispo(){
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [cours, setCours] = useState([]);
    const [loading, setLoading] = useState(true);
    const [coursDetailId, setCoursDetailId] = useRecoilState(coursDetailsIdState)

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };

    const config = {
        headers: headers,
        };
    
        useEffect(() => {
          axios.get(APP_URL+'students/cours-dispo',config).then((response) => {
             setCours(response.data.datas)
             console.log(response.data.datas);
             setLoading(false);
          }).catch((error) => console.log(error));
        },[]);

    return (
        <>
    <div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">Les cours</li>
     </ol>
    
     <h4> Liste des cours choisis pour vous </h4>
        <div className="alert alert-warning">
        Cliquez sur le bouton "Voir les détails" pour obtenir plus de détails sur un cours dont le titre vous intéresse        </div>
    
        {loading == true && <>
            <center>
            <br />
            <br />
 <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 30,
        }}
        spin
      />
    }
  />
            </center>
        </>}
    
    <div class="row">

        {cours.map((item) => (
            <>

     <div class="col-xl-5 col-sm-6 mb-5">
         {/* <div class="card dashboard text-white bg-primary o-hidden h-100">
           <div class="card-body">
             <div class="card-body-icon">
               <i class="fa fa-fw fa-book"></i>
              
             </div>
             <div class="mr-5"><h5> {item.nom} </h5></div>
           </div>
           <a class="card-footer text-white clearfix small z-1" onClick={() => { setCoursDetailId(item.id); setMainView('cours_detail') }} href="javascript:void(0)">
             <span class="float-left">Voir les Details</span>
             <span class="float-right"> 
               <i class="fa fa-angle-right"></i> 
             </span>
           </a>
         </div> */}
 <div class="card dashboard text-white bg-primary o-hidden h-100">
  <Card
  onClick={() => { setCoursDetailId(item.id); setMainView('cours_detail') }}
    style={{
      width: '100%',
    }}
    cover={

      <>
      {item.couverture !== null?<>

        <img
        alt="example"
        src={`${item.couverture}`}
       />

      </>:<>

      <img
        alt="example"
        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
      />

      </>}
      
      </>
    }
    actions={[
     
         <SendOutlined  key="sendout" />

    ]}
  >


    <Meta
      avatar={<Avatar src={`${item.profil_auteur}`} />}
      title={item.nom}
      description="Selectionner pour vous"
    />

  </Card>

</div>
       </div>

            </>
        )
    
        )}
       
    </div>

    </div>
    <br /><br /><br />
        </>
    );
}