import { useRecoilState } from "recoil";
import { elementState, mainViewState } from "../../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { APP_URL } from "../../../../URL";
import axios from 'axios';
import {message,Button, notification} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';

export default function ConfirmDeleteCourse({cours}){
    const [element,setElement] = useRecoilState(elementState);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(false);
    const[mainView, setMainView] = useRecoilState(mainViewState);


    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
      };
      
      const config = {
        headers: headers,
        };

        const yes = () => {
            setLoading(true);
            axios.get(APP_URL+'profs/delete-cours/'+cours.id,config).then((response) => {
                setLoading(false);
                message.success('Supprimer avec success');
                
                notification.open({
                    message:(
                       <div style={{ color: 'white' }}>
                         Suppression effectif
                       </div>
                      ),
                    description: '',
                    placement:'bottomLeft', 
                    icon: <CheckCircleOutlined />,
                    style: {
                        backgroundColor: 'green', // Couleur de fond personnalisée
                        color:'white',
                        // Autres styles personnalisés...
                      },
                  })
            }).catch((error) => {
                console.log(error);
            })
        }

        const no = () => {
            setMainView('course_list');
        }

        useEffect(() => {},[loading])

    return (
        <>
         <br /><br />
           <center>
                <h2>Vouslez-vous vraiment supprimer ce cours ?</h2>
                <br />
                <Button className="btn btn-danger" onClick={yes} loading={loading} style={{display:'inline',width:'15%'}}>OUI</Button>
                &nbsp;&nbsp;
                <button className="btn btn-primary" onClick={no} style={{display:'inline',width:'15%'}}>NON</button>
           </center>
           <br /><br />
        </>
    );
}