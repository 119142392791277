import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { APP_URL } from "../../../../URL";
import {Spin} from 'antd';
import {LoadingOutlined,ArrowRightOutlined} from '@ant-design/icons';
import { useRecoilState } from "recoil";
import { moduleIdState, moduleNameState, subViewState } from "../../../../atoms/AppAtome";

export default function ListeModule({cours}){

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [moduleId, setModuleId] = useRecoilState(moduleIdState);
    const [subView, setSubview] = useRecoilState(subViewState);
    const [moduleName, setModuleName] = useRecoilState(moduleNameState);
    
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
      };
      
      const config = {
        headers: headers,
        };

    useEffect(() => {

       
        axios.get(APP_URL+'profs/module-list/'+cours.id,config).then((response) => {

            setData(response.data.datas);
            console.log('ici les datas envoyes',response.data);
            setLoading(false);

        }).catch((error) => {
            console.log(error);
            setLoading(false);
        })

    },[]);
  

    return (
        <>
          {loading?<>
                <center>

<Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 54,
        }}
        spin
      />
    }
  />
  <br /><br />
                </center>
          </>:<>
          
         {data.map((item) => (
            <>
                <h4>
                    <ArrowRightOutlined />
                    &nbsp;
                    <button className="btn btn-primary" onClick={() => {
                        setModuleId(item.id);
                        setModuleName(item.titre)
                        setSubview('add_chap_form');
                    }} style={{display:'inline',width:'30%'}}>Ajouter un chapitre</button>
                     &nbsp; {item.titre}
                    &nbsp;
                </h4>
            </>
         ))}
          
          </>}
        </>
    );
}