import { useState } from "react";
import { coursIdState, mainViewState, subViewState } from "../../../../atoms/AppAtome";
import { useRecoilState } from "recoil";
import { useCookies } from 'react-cookie';
import {Button, notification} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons'
import axios from 'axios';
import { APP_URL } from "../../../../URL";

export default function CreateModule({cours}){

    const [bookData, setBookData] = useState({
        cours_id: cours.id,
        titre:'',
        description:''
      });
    
      const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };
  
    const [loading, setLoading] = useState(false);
    const [categories, setCategorie] = useState([]);
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [coursId, setCourId] = useRecoilState(coursIdState);
    const [subView, setSubview] = useRecoilState(subViewState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        
        axios.post(APP_URL+'profs/add_module',bookData,config).then((response) => {
            setLoading(false);

            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     {response.data.message}
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setSubview('liste_module');

        }).catch((error) => {
            console.log(error);
            setLoading(false);

            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Une erreur est survenue
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'red', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })
        })

      }    
  

    return (
        <>
           <h4>Creer et enregistrer un module</h4>
           <hr />
           <form onSubmit={handleSubmit} style={{background:'white', padding:'2%'}}>

           <label htmlFor="titre" className="form-label">
              Titre du module
            </label>
            <input
              type="text"
              className="form-control"
              id="titre"
              name="titre"
              value={bookData.titre}
              onChange={handleChange}
              required
            />

            <label htmlFor="titre" className="form-label">
              Description de la formation
            </label>
           <textarea name='description' value={bookData.description}  onChange={handleChange} className='form-control'></textarea>
           
           <br /><br/>
            <Button type='primary'  htmlType='submit' loading={loading}>Enregistre le nouveau cours</Button>
         
           </form>
        </>
    );
}