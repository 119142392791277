import './css/LoginPage.css'; // Assurez-vous d'avoir un fichier CSS séparé
import { langState } from '../atoms/AppAtome';
import axios from 'axios';
import { APP_URL } from '../URL';
import { Alert, Button, message, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import b from './assets/432398145_356544404025701_8967787591334529753_n.jpg';
import a from './assets/aca.png';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useCookies } from "react-cookie";
import { CheckCircleOutlined } from '@ant-design/icons';

export default function ResetPasswordEmail() {
    
    const { t } = useTranslation();
    const [lang, setLang] = useRecoilState(langState);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
  
    const [formData, setFormData] = useState({
      email:'',
    });

    const [formData2, setFormData2] = useState({
        code:'',
        pass1:'',
        pass2:'',
      });
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name] : e.target.value
      });
    };

    const handleChange2 = (e) => {
        setFormData2({
          ...formData2,
          [e.target.name] : e.target.value
        });
      };

    const handleLanguageChange = (selectedLang) => {
        setLang(selectedLang);
      };

      const handleSubmit = (e) => {

        e.preventDefault();
        setLoading(true);
        axios.post(APP_URL+'generate-code-for-reset',formData).then((response) => {
            console.log('isOk',response.data);
            setLoading(false);
            setSuccess(true);
            
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     {response.data.message}
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

        }).catch((error) => {
            setLoading(false);
            console.log(error);

            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Erreur: {error.response.data.message}
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'red', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })
        })

      }    

      const handleSubmit2 = (e) => {

        e.preventDefault();
        console.log('code',formData2)
        // if(formData2.pass1 === formData2.pass2){

        setLoading(true);
        axios.post(APP_URL+'check-code-reinit-password',formData2).then((response) => {
            console.log('isOk',response.data);
            setLoading(false);
           
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     {response.data.message}
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

            //   on redirige au formulaire de connexion
            navigate('/login')

        }).catch((error) => {
           
            setLoading(false);
            console.log(error);

            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Erreur: {error.response.data.message}
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'red', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })
        })

        //  }else{
            
        //     notification.open({
        //         message:(
        //            <div style={{ color: 'white' }}>
        //              les deux mot de pass ne correspondent pas
        //            </div>
        //           ),
        //         description: '',
        //         placement:'bottomLeft', 
        //         icon: <CheckCircleOutlined />,
        //         style: {
        //             backgroundColor: 'red', // Couleur de fond personnalisée
        //             color:'white',
        //             // Autres styles personnalisés...
        //           },
        //       })
        //  }

      }    
  

    return (
        <div className="login-container " id='b' >
      
        <br />

      {success? <>

        <div className="login-form" id='log'>
        <center>
       
            <a href='/login'>
              <img src={`${a}`} style={{height:'100px', width:'100px', borderRadius:'100%'}} />
            </a>
        </center>
        
        <form onSubmit={handleSubmit2}>
          <label htmlFor="username" style={{color:'black'}}>code de confirmation</label>
          <input type="text" name='code' required id="email" className='form-control' value={formData2.code} onChange={handleChange2} />
            <br />
          <label htmlFor="username" style={{color:'black'}}>Nouveau mot de passe</label>
          <input type="password" name='pass1' id="pass1" required className='form-control' value={formData2.pass1} onChange={handleChange2} />
            <br />

            <label htmlFor="username" required style={{color:'black'}}>Confirmation nouveau mot de passe</label>
          <input type="password" name='pass2' id="pass2" className='form-control' value={formData2.pass2} onChange={handleChange2} />
            <br />
         
          <button type="submit" disabled={loading} className='btn btn-primary'>confirmer le code</button>
        </form>
        <br /><br />
      </div>
      
      </>:<>

      <div className="login-form" id='log'>
        <center>
       
            <a href='/login'>
              <img src={`${a}`} style={{height:'100px', width:'100px', borderRadius:'100%'}} />
            </a>
        </center>
        
        <form onSubmit={handleSubmit}>
          <label htmlFor="username" style={{color:'black'}}>Email</label>
          <input type="text" name='email' required id="email" className='form-control' value={formData.email} onChange={handleChange} />
            <br />
         
          <button type="submit" disabled={loading} className='btn btn-primary'>envoyer le code</button>
        </form>
        <br /><br />
      </div>
      
      </>}
      


      <br /><br />
      {/* <center>
        &copy; made by <a href='https://www.consolidis.com' target='_blank'>Consolidis group</a>
      </center> */}

      <style jsx>{`
      
        #b{
         
          background-image: linear-gradient(to top, #4222c4, rgba(99, 89, 89, 0.712)), url("${b}");border-color:rgba(0, 0, 0, 0);border-width:0px;
        }

        #log{
          background:white;
          color:white;
        }
      
      `}</style>
    </div>
  );
};
