import { useRecoilState } from "recoil";
import Lookup from "../Lookup";
import { loadLookupState, mainViewState, openState } from "../../atoms/AppAtome";
import { useEffect, useRef, useState } from "react";

import { Button,FloatButton,Divider, Space, Tour,Modal } from "antd";
import axios from "axios";
import { APP_URL } from "../../URL";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Home from "./views/Home";
import CourseList from "./views/CourseList";
import CreateCourse from "./views/CreateCourse";
import logo from '../assets/aca.png';
import AddCoverCourse from "./views/AddCoverCourse"; 
import {QuestionCircleOutlined, UserSwitchOutlined} from "@ant-design/icons";
import MonCompte from "./views/MonCompte";
import UserForm from "./views/UserForm";
import UpdatePdp from "./views/UpdatePdp";

export default function DashboadProfs(){

    const [loadingLookup, setLoadingLookup] = useRecoilState(loadLookupState);
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [open2, setOpen2] = useRecoilState(openState);
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const navigate = useNavigate();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };
    
    const config = {
      headers: headers,
      };

      const showModal = () => {
        setOpen(true);
      };
    
      const handleOk = () => {
        // setModalText('The modal will be closed after two seconds');
        
        setConfirmLoading(true);
        setTimeout(() => {
          setOpen2(false);
          setConfirmLoading(false);
        }, 2000);
      };
      const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen2(false);
      };
    

    const logouter = () => {
        setLoading(true);

        axios.get(APP_URL + 'logout',config).then((response) => {
            setLoading(false);
            // Supprimer le cookie 'seduc_token'
            removeCookie('seduc_token');

            // navigate('/login');
            window.location.href = '/login';

        }).catch((error) => {
          console.log(error)
        })

    }

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [open, setOpen] = useState(true);

    // step 

    const steps = [
      {
        title: ' Poser vos problemes en cliquant ici',
        description: 'Si vous rencontrez des problèmes ou des difficultés sur l\'application, cliquez sur ce bouton pour expliquer votre problème ou faire une demande d\'explication afin qu\'une amélioration ou une explication vous soit fournie. ',
        target: () => ref1.current,
      },
      {
        title: 'Discuter avec vos etudiants ici',
        description: 'Quand vos étudiants rencontrent des difficultés, ils posent des questions et vous pouvez y répondre en cliquant sur ce bouton',
        target: () => ref2.current,
      },
    ];

    
    useEffect(() => {
      
        setLoadingLookup(false);

        // const handleBeforeUnload = (event) => {
        //   const message = " En Actualisant la page, vous serez renvoyer en page d'accueil. voulez-vous actualiser ?";
        //   event.returnValue = message; // Standard pour la plupart des navigateurs
        //   return message; // Pour Internet Explorer
        // };
    
        // window.addEventListener('beforeunload', handleBeforeUnload);
    
        // return () => {
        //   window.removeEventListener('beforeunload', handleBeforeUnload);
        // };

    },[cookies.seduc_token]);

    return (
        <>
    <Lookup />
    <nav class="navbar navbar-expand-lg navbar-dark bg-default fixed-top" id="mainNav">
    <a class="navbar-brand" href="javascript:void(0)">
        {/* <img src="img/logo.png" data-retina="true" alt="" width="165" height="36"/> */}
        <h4 style={{background:'white', width:'200px', padding:'2%'}}> <b style={{color:'blue'}}>
          &nbsp;&nbsp;
          <img src={`${logo}`} style={{height:'30px', width:'30px'}} />
          &nbsp;
          PROF
        </b></h4>
    </a>
    <br /><br />
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav navbar-sidenav" id="exampleAccordion">
        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
          <a class="nav-link" href="javascript:void(0)" onClick={() => setMainView('dashboad')}>
            <i class="fa fa-fw fa-dashboard"></i>
            <span class="nav-link-text">Dashboard</span>
          </a>
        </li>
		
		 {/* <li class="nav-item" data-toggle="tooltip" data-placement="right" title="My listings">
          <a class="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseMylistings" data-parent="#mylistings">
            <i class="fa fa-fw fa-list"></i> &nbsp;
            <span class="nav-link-text">Abonnement &nbsp; <span class="badge badge-pill badge-danger">6</span></span>
          </a>
          <ul class="sidenav-second-level collapse" id="collapseMylistings">
            <li>
              <a href="listings.html">Nouvel achat<span class="badge badge-pill badge-primary">6</span></a>
            </li>
			<li>
              <a href="listings.html">Abonnement simple<span class="badge badge-pill badge-success">6</span></a>
            </li>
			<li>
              <a href="listings.html">Expired <span class="badge badge-pill badge-danger">6</span></a>
            </li> 
          </ul>
        </li> */}
		
		{/* <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Add listing">
          <a class="nav-link" href="add-listing.html">
            <i class="fa fa-fw fa-plus-circle"></i>
            <span class="nav-link-text">Add listing</span>
          </a>
        </li> */}
		{/* <li class="nav-item" data-toggle="tooltip" data-placement="right" title="My profile">
          <a class="nav-link" href="javascript:void(0)" onClick={() => setMainView('ecoles')}>
            <i class="fa fa-fw fa-users"></i> &nbsp;&nbsp;
            <span class="nav-link-text">Mes Etudiants</span>
          </a>
        </li> */}

        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Components">
          <a class="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseComponents2" data-parent="#Components">
            <i class="fa fa-fw fa-book"></i>&nbsp;&nbsp;
            <span class="nav-link-text">Mes cours</span>
          </a>
          <ul class="sidenav-second-level collapse" id="collapseComponents2">
            <li>
              <a href="javascript:void(0)" onClick={() => setMainView('course_list')} >Liste des cours</a>
            </li>
			<li>
              <a href="javascript:void(0)" onClick={() => setMainView('course_create')} >Creer un cours</a>
            </li>
          </ul>
        </li>

		<li class="nav-item" data-toggle="tooltip" data-placement="right" title="Components">
          <a class="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseComponents" data-parent="#Components">
            <i class="fa fa-fw fa-gear"></i>&nbsp;&nbsp;
            <span class="nav-link-text">Cofigurations</span>
          </a>
          <ul class="sidenav-second-level collapse" id="collapseComponents">
            <li>
              <a href="javascript:void(0)" onClick={() => setMainView('mon_compte')}>Mon compte</a>
            </li>
			{/* <li>
              <a href="tables.html">Tables</a>
            </li> */}
          </ul>
        </li>
      </ul>
      <ul class="navbar-nav sidenav-toggler">
        <li class="nav-item">
          <a class="nav-link text-center" id="sidenavToggler">
            <i class="fa fa-fw fa-angle-left"></i>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle mr-lg-2" id="messagesDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-fw fa-envelope"></i>
            <span class="d-lg-none">Messages
              <span class="badge badge-pill badge-primary">12 New</span>
            </span>
            <span class="indicator text-primary d-none d-lg-block">
              <i class="fa fa-fw fa-circle"></i>
            </span>
          </a>
          <div class="dropdown-menu" aria-labelledby="messagesDropdown">
            <h6 class="dropdown-header">Nouveau Messages:</h6>
            <div class="dropdown-divider"></div>
            
            <a class="dropdown-item" href="#">
              <strong>AcademieCreatifs</strong>
              <span class="small float-right text-muted">11:21 AM</span>
              <div class="dropdown-message small">Bienvenue et merci d'utiliser notre plateforme</div>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item small" href="#">Voir tout messages</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle mr-lg-2" id="alertsDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-fw fa-bell"></i>
            <span class="d-lg-none">Alerts
              <span class="badge badge-pill badge-warning">6 New</span>
            </span>
            <span class="indicator text-warning d-none d-lg-block">
              <i class="fa fa-fw fa-circle"></i>
            </span>
          </a>
          <div class="dropdown-menu" aria-labelledby="alertsDropdown">
            <h6 class="dropdown-header">Notifications:</h6>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">
              <span class="text-success">
                <strong>
                  <i class="fa fa-long-arrow-up fa-fw"></i>Mise a jours de nos conditions d'utilisation</strong>
              </span>
              <span class="small float-right text-muted">11:21 AM</span>
              <div class="dropdown-message small">Prennez connaissance de nos mise a jours des conditions d'utilisations.</div>
            </a>
           
            <div class="dropdown-divider"></div>
            <a class="dropdown-item small" href="#">Voir toutes les notifications</a>
          </div>
        </li>
        <li class="nav-item">
          <form class="form-inline my-2 my-lg-0 mr-lg-2">
            <div class="input-group">
              <input class="form-control search-top" type="text" placeholder="Rechercher..." />
              <span class="input-group-btn">
                <button class="btn btn-primary" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </form>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" data-target="#exampleModal">
            <i class="fa fa-fw fa-sign-out"></i>Logout</a>
        </li>
      </ul>
    </div>
  </nav>

  <div class="content-wrapper">
        {mainView === 'dashboad' && <Home />}
        {mainView === 'course_list' && <CourseList />}
        {mainView === 'course_create' && <CreateCourse />}
        {mainView === 'add_cover_course' && <AddCoverCourse />}
        {mainView === 'mon_compte' && <MonCompte />}
        {mainView === 'user_form' && <UserForm />} 
        {mainView === 'update_pdp' && <UpdatePdp />}
  </div>
   
    <footer class="sticky-footer">
      <div class="container">
        <div class="text-center">
          <small> © <u>L'academie des creatifs</u> made by <a href="https://www.consolidis.com" target="_blank">Consolidis</a></small>
        </div>
      </div>
    </footer>
   
   
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" style={{width:'100%'}}>Voulez-vous vraiment vous deconnecter?</h5>
            <button class="close" type="button" style={{width:'10%'}} data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">Cliquez sur "oui" si vous êtes pret a laisser votre session</div>
          <div class="modal-footer">
            <button class="btn btn-secondary" style={{width:'50%'}} type="button" data-dismiss="modal">NON</button>
            <Button class="btn btn-primary" type="primary" loading={loading} onClick={logouter} style={{width:'50%'}} href="javascript:void(0)">OUI</Button>
          </div>
        </div>
      </div>
    </div>
   

    <Space>
    <FloatButton.Group
      shape="circle"
      style={{
        right: 24,
      }}
    >
    
      <FloatButton  ref={ref1}   icon={<QuestionCircleOutlined />} />
     
      <FloatButton badge={{
          count: 5,
          color: 'red',
        }}
        // onClick={() => alert()}
        icon={<UserSwitchOutlined />}
        ref={ref2}
      />

      <FloatButton.BackTop visibilityHeight={0} />
     
    </FloatButton.Group>

    </Space>
    <Tour open={open} onClose={() => setOpen(false)} steps={steps} />


    {/* <Modal
        title="Fonctionnalité en développement"
        open={open2}
        footer={null}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={700}
      >
        
          <h5>L'équipe technique travail sur cette Fonctionnalité qui sera dispo d'ici peu </h5>
         {element === 'modification' && <ModificationChap cours={cours} />}
         {element === 'chapitre_list' && <ChapitreList cours={cours} />}
         {element === 'modules_list' && <ModuleList cours={cours} />}
         {element === 'promos_list' && <PromosList cours={cours} />}
         {element === 'delete_course' && <ConfirmDeleteCourse cours={cours}  />}

      </Modal> */}

    
        </>
    );
}