import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { coursIdState, openState, subViewState } from "../../../../atoms/AppAtome";
import {Button} from 'antd'
import UpdateData from "./UpdateDatas";
import AddCoverCourse from "../AddCoverCourse";


export default function ModificationChap({cours}){

    const [subView, setSubview] = useRecoilState(subViewState);
    const [coursId, setCoursId] = useRecoilState(coursIdState);
    const [change, setChange] = useState(false);
    const [open, setOpen] = useRecoilState(openState);

    useEffect(() => {
        setCoursId(cours.id);
        setSubview('');
    },[open]);

    return (
        <>
            <h5>Modification du cours "{cours.nom}"</h5>
            <hr />
            <Button type="submit" onClick={() => { setSubview('update_data'); setChange(true)}} className="btn btn-primary" style={{width:'40%', display:'inline'}}>Modification des donnees</Button>
            &nbsp;&nbsp;&nbsp;
            <Button type="submit" onClick={() => { setSubview('update_couverture'); setChange(true) }} className="btn btn-primary" style={{width:'40%', display:'inline'}}>Modification photo de couverture</Button>
            <hr />

            {subView === 'update_data' && <UpdateData cours={cours} /> }
            {subView === 'update_couverture' && <AddCoverCourse />}
        </>
    );
}