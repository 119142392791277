import { useRecoilState } from "recoil";
import { elementState, moduleIdState, moduleNameState } from "../../../../atoms/AppAtome";
import { useState } from "react";
import { useCookies } from 'react-cookie';
import {Button,notification} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';
import axios from 'axios';
import { APP_URL } from "../../../../URL";


export default function AddChapForm({cours}){

    const [moduleId, setModuleId] = useRecoilState(moduleIdState);
    const [moduleName, setModuleName] = useRecoilState(moduleNameState);
    const [loading, setLoading] = useState(false);
    const [element,setElement] = useRecoilState(elementState);
    const [chapitreData, setChapitreData] = useState({
        cours_id: cours.id,
        module_id:moduleId,
        titre:'',
        description:'',
        duree:'',
        video_url:'',
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setChapitreData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    
      };
    
      const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            setLoading(true);

            axios.post(APP_URL+'profs/create-chapitre/'+cours.id,chapitreData,config).then((response) => {
                console.log('chapitres',response.data);
                setLoading(false);
                setElement('chapitre_list');

                notification.open({
                    message:(
                       <div style={{ color: 'white' }}>
                         {response.data.message}
                       </div>
                      ),
                    description: '',
                    placement:'bottomLeft', 
                    icon: <CheckCircleOutlined />,
                    style: {
                        backgroundColor: 'green', // Couleur de fond personnalisée
                        color:'white',
                        // Autres styles personnalisés...
                      },
                  })
    
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                notification.open({
                    message:(
                       <div style={{ color: 'white' }}>
                         Une erreur est survenue
                       </div>
                      ),
                    description: '',
                    placement:'bottomLeft', 
                    icon: <CheckCircleOutlined />,
                    style: {
                        backgroundColor: 'red', // Couleur de fond personnalisée
                        color:'white',
                        // Autres styles personnalisés...
                      },
                  })

            })

        }

    return (
        <>
<h5>Chapitre du module: {moduleName}</h5>
<form onSubmit={handleSubmit} style={{background:'white', padding:'2%'}}>

<label htmlFor="titre" className="form-label">
   Titre du chapitre
 </label>
 <input
   type="text"
   className="form-control"
   id="titre"
   name="titre"
   value={chapitreData.titre}
   onChange={handleChange}
   required
 />

<label htmlFor="titre" className="form-label">
   Description ou resume du chapitre
 </label>
 <textarea
   className="form-control"
   id="titre"
   name="description"
   value={chapitreData.description}
   onChange={handleChange}
   required
 ></textarea>

<label htmlFor="duree" className="form-label">
   Duree de la video(en heure)
 </label>
 <input
   type="text"
   className="form-control"
   id="duree"
   name="duree"
   value={chapitreData.duree}
   onChange={handleChange}
   required
 />

<label htmlFor="video_url" className="form-label">
   adresse URL de la video
 </label>
 <input
   type="url"
   className="form-control"
   id="video_url"
   name="video_url"
   value={chapitreData.video_url}
   onChange={handleChange}
   required
 />

<Button type='primary'  htmlType='submit' loading={loading}>Enregistre le chapitre</Button>


 </form>
        </>
    );
}