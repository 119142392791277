import { useRecoilState } from "recoil";
import { coursDetailsIdState, mainViewState } from "../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../URL";
import { useNavigate } from "react-router-dom";
import { Spin, notification } from "antd";
import { useCookies } from "react-cookie";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function ProcessAccessFree(){

    const [coursDetailId, setCoursDetailId] = useRecoilState(coursDetailsIdState)
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [loading, setLoading] = useState(true);
    const navigation = useNavigate();
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    
    useEffect(() => {
        axios.post(APP_URL+'students/add-abonnement/'+coursDetailId,{},config).then((response) => {

            console.log('res',response.data);

            if(response.data.message == 'ok'){

                notification.open({
                    message:(
                       <div style={{ color: 'white' }}>
                         abonnee avec success
                       </div>
                      ),
                    description: '',
                    placement:'bottomLeft', 
                    icon: <CheckCircleOutlined />,
                    style: {
                        backgroundColor: 'green', // Couleur de fond personnalisée
                        color:'white',
                        // Autres styles personnalisés...
                      },
                  })

                setMainView('learn_view'); // on affiche la page de suivie du cours
            }

           

        }).catch((error) => {
            console.log(error);

            if(error.response.data.code == 400){

                notification.open({
                    message:(
                       <div style={{ color: 'white' }}>
                         {error.response.data.message}, vous pouvez le suivre maintenant
                       </div>
                      ),
                    description: '',
                    placement:'bottomLeft', 
                    icon: <CheckCircleOutlined />,
                    style: {
                        backgroundColor: 'red', // Couleur de fond personnalisée
                        color:'white',
                        // Autres styles personnalisés...
                      },
                  })

                  setMainView('learn_view') // on retourne vers les details du cours
            }

        });

    },[]);

    return (
        <>
            <div class="container-fluid">

                <br /><br />
                <center>
                    {loading == true && <><Spin size="large" /> </> }
                </center>

            </div>
        </>
    )
}