import { useRecoilState } from "recoil";
import { coursDetailsIdState } from "../../../atoms/AppAtome";
import { APP_URL } from "../../../URL";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Spin } from "antd";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";


export default function Details(){

    const [coursDetailId, setCoursDetailId] = useRecoilState(coursDetailsIdState)
    const [cours, setCours] = useState([]);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(true);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    useEffect(() => {
        axios.get(APP_URL+'students/cours-detail/'+coursDetailId,config).then((response) => {
            console.log('LES DET', response.data);
            setCours(response.data);

            setLoading(false);
            console.log(response.data);

        }).catch((error) => {
            console.log(error); // erreir
        } )

    },[]); 

    return (
        <>

{loading == true? <>

<center>
 <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 30,
        }}
        spin
      />
    }
  />
</center>

    </>:<>

        <center>
            <img src={`${cours.datas.couverture}`} />
        </center>
                   <b>Titre:</b> {cours.datas.titre} <hr />
                   <b>Auteur:</b> {cours.datas.auteur} <hr />
                   <b>Prix:</b> {cours.datas.mode != 'Payant'? <>
                                <div className="badge badge-success">Gratuit</div>
                            </>:<>
                                <div className="badge badge-warning">{cours.datas.prix} xaf </div>
                            </>}
                         <hr />

                   <b>Langue:</b> {cours.datas.langue} <hr />
                   <b>Description</b><br />
                   {cours.datas.description}


    {cours == {} && <>
       <center>
         <h5 style={{color:'gray'}}> <InboxOutlined /> <br />
            Aucun chapitre pour le moment
          </h5>
       </center>
       
     </>}

    </>}

<br /><br /><br />
</>
    );
}