import { useRecoilState } from "recoil";
import { coursDetailsIdState } from "../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import { Card } from "antd";
import Details from "../viewCard/Detail";
import Paiement from "../viewCard/Paiement";
import Chapitre from "../viewCard/Chapitre";

const tabList = [
    {
      key: 'tab1',
      tab: 'tab1',
    },
    {
      key: 'tab2',
      tab: 'tab2',
    },
  ];

  const contentList = {
    tab1: <p>content1</p>,
    tab2: <p>content2</p>,
  };

  const tabListNoTitle = [
    {
      key: 'detail',
      label: 'Detail',
    },
    {
      key: 'paiement',
      label: 'Paiement',
    },
    {
      key: 'chapitres',
      label: 'Chapitres',
    },
  ];

  const contentListNoTitle = {
    detail: <Details />,
    paiement: <Paiement /> ,
    chapitres: <Chapitre />,
  };

export default function CoursDetails(){
    const [coursDetailId, setCoursDetailId] = useRecoilState(coursDetailsIdState)
    const [activeTabKey1, setActiveTabKey1] = useState('tab1');
    const [activeTabKey2, setActiveTabKey2] = useState('detail');
    const onTab1Change = (key) => {
      setActiveTabKey1(key);
    };
    const onTab2Change = (key) => {
      setActiveTabKey2(key);
    };

    useEffect(() => {

      // coursDetailId

    },[]);

    return (
        <>
       <div class="col-xl-12 col-sm-12 mb-12">

        <h4> Les details du cours ci-dessous </h4>
      <br />

      <Card

        style={{
          width: '100%',
        }}
        tabList={tabListNoTitle}
        activeTabKey={activeTabKey2}
        tabBarExtraContent={<a href="#">More</a>}
        onTabChange={onTab2Change}
        tabProps={{
          size: 'middle',
        }}
      >

        {contentListNoTitle[activeTabKey2]}

      </Card>

      </div>
        </>
    );
}