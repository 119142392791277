import { InboxOutlined } from '@ant-design/icons';
import { message, Upload,notification } from 'antd';
import { useRecoilState } from 'recoil';
import { coursIdState, mainViewState } from '../../../atoms/AppAtome';
import { APP_URL } from '../../../URL';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useState } from 'react';
import { PlusOutlined } from "@ant-design/icons";
import { CheckCircleOutlined } from '@ant-design/icons';

const getBase64 = (file) => 
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });


export default function UpdatePdp(){

  let [coursId, setCoursId] = useRecoilState(coursIdState);
  const [mainView, setMainView] = useRecoilState(mainViewState);
  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
  const [fileList, setFileList] = useState([]);
  const [selected, setSelected] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList }) => { setFileList(newFileList); setSelected(true); }
  
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${cookies.seduc_token}`
    };
  
  const config = {
    headers: headers,
    };

    return (
        <>
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="#">Dashboard</a>
          </li>
          <li class="breadcrumb-item active">photo de profil</li>
        </ol>
        <h3>Modifier votre photo de profil aussi si vous voulez</h3>
        <div className="container-fluid" style={{ background: 'white', padding: '2%' }}>
          <center>
          <Upload
            customRequest={(file) => {
             
              return axios.post(`${APP_URL}update-pdp`, {
                file,
              },config).then((response) => {
                console.log(response.data)
                setFileList(response.data);

                notification.open({
                  message:(
                     <div style={{ color: 'white' }}>
                       Enregistrer avec success
                     </div>
                    ),
                  description: '',
                  placement:'bottomLeft', 
                  icon: <CheckCircleOutlined />,
                  style: {
                      backgroundColor: 'green', // Couleur de fond personnalisée
                      color:'white',
                      // Autres styles personnalisés...
                    },
                })
                
                setMainView('mon_compte'); 

              }).catch((error) => {
                console.log(error.response.data);
              });
            }}

            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            name="file"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>

          </center>
        </div>
      </div>

        </>
    );
}