import { useRecoilState } from "recoil";
import { coursDetailsIdState } from "../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../URL";
import { useCookies } from "react-cookie";
import { Spin } from "antd";
import { InboxOutlined, LoadingOutlined, ReadOutlined } from "@ant-design/icons";


export default function Chapitre(){
    const [coursDetailId, setCoursDetailId] = useRecoilState(coursDetailsIdState)
    const [cours, setCours] = useState([]);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(true);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    useEffect(() => {
        axios.get(APP_URL+'students/chap-list/'+coursDetailId,config).then((response) => {
            console.log('LES DET', response.data);
            setCours(response.data.datas);

            setLoading(false);

        }).catch((error) => {
            console.log(error); // erreir
        } )

    },[]); 

    return (
        <>
            {cours.map((item) => (
                <>
                    <h5 style={{color:'gray'}}> <ReadOutlined /> {item.titre}</h5>
                </>
            ))}

    {loading == true? <>

<center>
 <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 30,
        }}
        spin
      />
    }
  />
</center>

    </>:<>
     {cours.length == 0 && <>
       <center>
         <h5 style={{color:'gray'}}> <InboxOutlined /> <br />
            Aucun chapitre pour le moment
          </h5>
       </center>
       
     </>}
    </>}

</>)
}