import { useState } from "react";
import { useCookies } from 'react-cookie';
import { useRecoilState } from "recoil";
import { coursIdState, mainViewState, subViewState } from "../../../../atoms/AppAtome";
import axios from 'axios';
import { APP_URL } from "../../../../URL";
import {notification,Button} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';

export default function CreatePromos({cours}){

    const [promosData, setPromosData] = useState({
        cours_id: cours.id,
        start_at:'',
        end_at:'',
        code:'',
        reduction:'',
      });

      const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
      const [loading, setLoading] = useState(false);
      const [categories, setCategorie] = useState([]);
      const [mainView, setMainView] = useRecoilState(mainViewState);
      const [coursId, setCourId] = useRecoilState(coursIdState);
      const [subView, setSubview] = useRecoilState(subViewState);
  

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

        const handleChange = (e) => {
            const { name, value } = e.target;
            setPromosData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
        
          };
        
          const handleSubmit = (e) => {
            e.preventDefault();
            setLoading(true);

            axios.post(APP_URL+'profs/add-promos',promosData,config).then((response) => {
                setLoading(false);

                notification.open({
                    message:(
                       <div style={{ color: 'white' }}>
                         {response.data.message}
                       </div>
                      ),
                    description: '',
                    placement:'bottomLeft', 
                    icon: <CheckCircleOutlined />,
                    style: {
                        backgroundColor: 'green', // Couleur de fond personnalisée
                        color:'white',
                        // Autres styles personnalisés...
                      },
                  })

            }).catch((error) => {
                setLoading(false);
                console.log(error);
            });

          }

    return (
        <>
          <h5>Creer une nouvelle promotion maintenant</h5>
          <div className="alert alert-warning">Creer de preference 1 promo par cours</div>
          <form onSubmit={handleSubmit} style={{background:'white', padding:'2%'}}>

<label htmlFor="titre" className="form-label">
   Date de debut de la promos
 </label>
 <input
   type="date"
   className="form-control"
   id="titre"
   name="start_at"
   value={promosData.start_at}
   onChange={handleChange}
   required
 />

<label htmlFor="titre" className="form-label">
   Date de fin de la promos
 </label>
 <input
   type="date"
   className="form-control"
   id="titre"
   name="end_at"
   value={promosData.end_at}
   onChange={handleChange}
   required
 />

<label htmlFor="titre" className="form-label">
   Code de la promos
 </label>
 <input
   type="text"
   className="form-control"
   id="titre"
   name="code"
   value={promosData.code}
   onChange={handleChange}
   required
 />

<label htmlFor="titre" className="form-label">
   Pourcentage de reduction
 </label>
 <input
   type="number"
   className="form-control"
   id="titre"
   name="reduction"
   value={promosData.reduction}
   onChange={handleChange}
   required
 />

<br /><br/>
            <Button type='primary'  htmlType='submit' loading={loading}>Enregistre la promos</Button>
         

 </form>
        </>
    );
}