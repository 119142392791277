import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../atoms/AppAtome";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { APP_URL } from "../../../URL";
import {Button} from 'antd';
import {notification} from 'antd';
import {CheckCircleOutlined} from '@ant-design/icons';

export default function UserForm(){
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

        useEffect(() => {

            axios.get(APP_URL + 'data-user',config).then((response) => {
                setData(response.data);
                console.log(response.data);
            }).catch((error) => {
                console.log(error);
            })

        },[]);

    const [userData, setUserData] = useState({
        nom:data.nom,
        prenom: data.prenom,
        ville:data.ville,
        phone:data.phone,
        genre:'Masculin',
        pays:data.pays,
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
         axios.post(APP_URL+'update-data',userData,config).then((response) => {

            setMainView('update_pdp');

            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

         }).catch((error) => {
            console.log(error);
         })
      }

    return (
        <>
 <div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">Modifier vos informations de compte</li>
     </ol>

     <div className="container" style={{width:'80%'}}>
        <center>
        <form onSubmit={handleSubmit} style={{background:'white', padding:'2%'}}>
            <h5>Modifier vos informations de compte &nbsp;&nbsp; <button className="btn btn-primary" htmlType='button' onClick={() => setMainView('update_pdp')} style={{display:'inline',width:'17%'}}>photo de profil</button></h5>
            <hr />

        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              nom
            </label>
            <input
              type="text"
              className="form-control"
              id="nom"
              name="nom"
              value={userData.nom}
              onChange={handleChange}
              required
            />
        </div>
        <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              prenom
            </label>
            <input
              type="text"
              className="form-control"
              id="prenom"
              name="prenom"
              value={userData.prenom}
              onChange={handleChange}
              required
            />
        </div>
        <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              ville
            </label>
            <input
              type="text"
              className="form-control"
              id="ville"
              name="ville"
              value={userData.ville}
              onChange={handleChange}
              required
            />
        </div>
        <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              phone
            </label>
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              value={userData.phone}
              onChange={handleChange}
              required
            />
        </div>
        <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              pays
            </label>
            <input
              type="text"
              className="form-control"
              id="pays"
              name="pays"
              value={userData.pays}
              onChange={handleChange}
              required
            />
        </div>
        <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              genre
            </label>
            <select name="genre" className="form-control" value={userData.genre} onChange={handleChange}>
                <option value={'Masculin'}>Masculin</option>
                <option value={'Feminin'}>Feminin</option>
            </select>
           
        </div>

        <div className="col-md-12 mb-12">
            <button  disabled={loading} htmlType='submit' className=" btn btn-primary">Enregistrement</button> 
        </div>
    </div>

   </form>
        </center>
    </div>
 </div>
 <br /><br /><br /><br />
        </>
    );
}