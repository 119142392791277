import { useEffect, useState } from "react";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { APP_URL } from "../../../URL";
import pdp from '../../assets/user_icon.png'
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../atoms/AppAtome";

export default function MonCompte(){

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [data,setData] = useState({});
    const [mainView, setMainView] = useRecoilState(mainViewState);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };
  
    useEffect(() => {

        axios.get(APP_URL + 'data-user',config).then((response) => {
            setData(response.data);
        }).catch((error) => {
            console.log(error);
        })

    },[])

    return (
        <>
    <div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">Votre profil</li>
     </ol>

     <div className="container" style={{width:'60%'}}>
        <center>
        
            {/* {data.pdp == null ? <>
                <img src={`${pdp}`} style={{height:'95px', width:'120px', borderRadius:'100%'}} />
            </>:<> */}
            <img src={`${data.profil}`} style={{height:'100px', width:'100px', borderRadius:'100%'}} />
            {/* </>} */}
           
        </center>
        <hr />
        <b>Nom: {data.nom}</b> 
        <hr />
        <b>Prenom: {data.prenom}</b>
        <hr />
        <b>Ville: {data.ville}</b>
        <hr />
        <b>Genre: {data.genre}</b>
        <hr />
        <b>Pays: {data.pays}</b>
        <hr />
        <b>Phone: {data.phone}</b>
        <hr />
        <b>Biographie: {data.bio}</b>
        <hr />
        
        <button className="btn btn-primary" onClick={() => setMainView('user_form')}> Modifier vos infos <span className="fa fa-user"></span></button>
        </div>
     </div>
   
     <br /><br /><br />
        </>
    );
}