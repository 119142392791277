import { atom } from "recoil";
      
export const langState = atom({
    key:'lange',
    default:'fr'
})

export const loadLookupState = atom({
    key:'loadLookup',
    default:false
});

export const mainViewState = atom({
    key:'mainView',
    default:'dashboad'
});

export const rentreeEsbState = atom({
    key: 'rentreeEsb',
    default: 1,
})

export const relationIdState = atom({
    key:'relationId',
    default:null
}); // cette atom doit contenir temporairement l'id des entitee pour les relations

export const coursIdState = atom({
    key:'coursId',
    default:0,
})

export const subViewState = atom({
    key:'subview',
    default:'',
})

export const elementState = atom({
    key:'element',
    default:'',
})

export const openState = atom({
    key:'open',
    default:false,
});

export const moduleIdState = atom({
    key:'moduleId',
    default:0,
})

export const moduleNameState = atom({
    key:'moduleName',
    default:'',
})

export const coursDetailsIdState = atom({
    key:'cours_details' ,
    default:0,
})





// ----------------------------------------- Les atomes cidessous serons enregistrer dans le localStorage pour etre reutiliser ulterieurement
export const isOkProcessState = atom({
    key: 'isOk',
    default: true,
}) // cet atom va permettre de savoir si l'enregistrement de l'etape est ok ou non

export const detailProcessState = atom({
    key:'detailProcess',
    default:''
}); // cet atom va permettre d'avoir les information sur les details en cas d'erreur 

export const keyViewProcessState = atom({
    key:'keyViewProcess',
    default:1
}) // cet atom va permettre de savoir sur quelle screen l'utilisateur s'est arreter

export const rapportIdState = atom({
    key:'rapportId',
    default: null
}) // cet atom me permet de faire transiter la valeur de l'id du rapport dans l'enssemble des composants

export const rentreeESBIdState = atom({
    key:'rentreeESBIdBaseId',
    default: null
}) // cet atom me permet de faire transiter la valeur de l'id de la rentree de l'enseignement de base dans l'enssemble des composants
