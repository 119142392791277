import { useRecoilState } from "recoil";
import { coursIdState, elementState, subViewState } from "../../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import {notification,Spin,Tooltip,Button} from 'antd';
import {CheckCircleOutlined,LoadingOutlined,FullscreenOutlined,EditOutlined,DeleteOutlined} from '@ant-design/icons';


export default function ChapitreList({cours}){

    const [subView, setSubview] = useRecoilState(subViewState);
    const [coursId, setCoursId] = useRecoilState(coursIdState);
    const [element,setElement] = useRecoilState(elementState);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [datas, setDatas] = useState([]);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
      };
      
      const config = {
        headers: headers,
        };

    const turnStatus = (status,chap) => {
        setLoading2(true);
        axios.get(APP_URL + 'profs/turn-to-read-chap/'+chap+'/'+status,config).then((response) => {
            setLoading2(false);
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     {response.data.message}
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

        }).catch((error) => {
            console.log(error);
            setLoading2(false);
        });
    }

    const deleteChap = (chap) => {
        setLoading3(true);
        axios.delete(APP_URL+'profs/delete-chapitre/'+chap,config).then((response) => {
            setLoading3(false);
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     {response.data.message}
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

        }).catch((error) => {
            console.log(error);
            setLoading3(false);
        })
    }

    useEffect(() => {
        axios.get(APP_URL+'profs/chapitre-list/'+cours.id,config).then((response) => {
            setDatas(response.data.datas);
            setLoading(false);
            console.log('chapp',response.data);

        }).catch((error) => {
            console.log(error);
            setLoading(false);

            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Une erreur est survenue
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'red', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })
        })
    },[loading2,loading3]);

    return (
        <>
          <h3> Liste des chapitres du cours "{cours.nom}" </h3>
          <div className="alert alert-warning">Pour creer un nouveu chapitre, allez dans les modules en cliquant &nbsp;<button classname="btn btn-primary" onClick={() => setElement('modules_list')} style={{display:'inline',width:'10%'}}>ici</button></div>

            {loading?<>
            
            <center>
    <br /><br />
<Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 54,
        }}
        spin
      />
    }
  />
    <br /><br />
       </center>
            
            </>:<>
               {datas.map((item) => (
                 <>
                    <h5>
                        {item.etat === false?<>
                            <Tooltip placement="top" title="Publier le chapitre maintenant">
                            <Button className="btn btn-success" key={item.id}  onClick={() => {turnStatus(1,item.id)}} style={{display:'inline', width:'7%'}}>
                                <FullscreenOutlined />
                            </Button>
                            </Tooltip>
                        </>:<>
                            <Tooltip placement="top" title="Depublier le chapitre maintenant">
                            <Button className="btn btn-danger" key={item.id}  onClick={() => {turnStatus(0,item.id)}} style={{display:'inline', width:'7%'}}>
                                <FullscreenOutlined />
                            </Button>
                            </Tooltip>
                        </>}
                        {/* &nbsp;
                           <Tooltip placement="top" title="Modifier le chapitre maintenant">
                            <button className="btn btn-warning" style={{display:'inline', width:'7%'}}>
                                <EditOutlined />
                            </button>
                            </Tooltip> */}
                        &nbsp;
                        <Tooltip placement="top" title="Supprimer le chapitre maintenant">
                            <button className="btn btn-danger" onClick={() => {deleteChap(item.id)}} style={{display:'inline', width:'7%'}}>
                                <DeleteOutlined />
                            </button>
                            </Tooltip>
                        &nbsp;
                        {item.titre}
                    </h5>
                 </>
               ))}
            </>}
        </>
    );
}