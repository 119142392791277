import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../URL";
import CoursDispo from "./CoursDispo";


export default function Home(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [cours, setCours] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    useEffect(() => {

        axios.get(APP_URL+'students/get-my-course',config).then((response) => {

            setLoading(false);
            setCours(response.data.datas)

        }).catch((error) => {

            console.log(error);
        });

    },[]);

    return (
        <>
             <div class="container-fluid">
     
     {/* <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">My Dashboard</li>
     </ol> */}
    
     <div class="row">
       <div class="col-xl-12 col-sm-12 mb-12">
         <div class="card dashboard text-white bg-primary o-hidden h-100">
           <div class="card-body">
             <div class="card-body-icon">
               <i class="fa fa-fw fa-book"></i>
             </div>
             <div class="mr-5"><h5>Vous avez {cours.length} cours dans vos actifs </h5></div>
           </div>
           <a class="card-footer text-white clearfix small z-1" onClick={() => setMainView('learn_view')} href="javascript:void(0)">
             <span class="float-left">Voir les Details</span>
             <span class="float-right">
               <i class="fa fa-angle-right"></i>
             </span>
           </a>
         </div>
       </div>
       </div>
    
     <br />

       <CoursDispo />

       </div>
        </>
    );
}

