import { useEffect, useState } from "react";
import { Button,FloatButton,Divider, Space, Tour } from "antd";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadLookupState, mainViewState } from "../../../atoms/AppAtome";
import { APP_URL } from "../../../URL";

export default function Home(){

   const [cours, setCours] = useState(0);
   const [achat,setAchats] = useState(0);
   const [abonnement, setAbonnement] = useState(0);
   const [module, setModule] = useState(0);

   const [loadingLookup, setLoadingLookup] = useRecoilState(loadLookupState);
   const [mainView, setMainView] = useRecoilState(mainViewState);
   const [loading, setLoading] = useState(false);
   const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
   const navigate = useNavigate();

   const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookies.seduc_token}`
    };
  
  const config = {
    headers: headers,
    };

    useEffect(() => {
      axios.get(APP_URL+'profs/state-datas',config).then((response) => {
          setCours(response.data.datas.cours);
          setModule(response.data.datas.module);
          setAbonnement(response.data.datas.abonnement);
      }).catch((error) => console.log(error));
    },[]);


    return (
        <>
        
    <div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">My Dashboard</li>
     </ol>
    
     <div class="row">
       <div class="col-xl-3 col-sm-6 mb-3">
         <div class="card dashboard text-white bg-primary o-hidden h-100">
           <div class="card-body">
             <div class="card-body-icon">
               <i class="fa fa-fw fa-book"></i>
             </div>
             <div class="mr-5"><h5>{cours} Cours creer!</h5></div>
           </div>
           <a class="card-footer text-white clearfix small z-1" onClick={() => setMainView('course_list')} href="javascript:void(0)">
             <span class="float-left">Voir les Details</span>
             <span class="float-right">
               <i class="fa fa-angle-right"></i>
             </span>
           </a>
         </div>
       </div>
       <div class="col-xl-3 col-sm-6 mb-3">
         <div class="card dashboard text-white bg-warning o-hidden h-100">
           <div class="card-body">
             <div class="card-body-icon">
               <i class="fa fa-fw fa-money"></i>
             </div>
               <div class="mr-5"><h5>0 Cours acheter!</h5></div>
           </div>
           <a class="card-footer text-white clearfix small z-1" href="javascript:void(0)">
             <span class="float-left">Voir les Details</span>
             <span class="float-right">
               <i class="fa fa-angle-right"></i>
             </span>
           </a>
         </div>
       </div>
       <div class="col-xl-3 col-sm-6 mb-3">
         <div class="card dashboard text-white bg-success o-hidden h-100">
           <div class="card-body">
             <div class="card-body-icon">
               <i class="fa fa-fw fa-users"></i>
             </div>
             <div class="mr-5"><h5>{abonnement} Abonnement!</h5></div>
           </div>
           <a class="card-footer text-white clearfix small z-1" href="javascript:void(0)">
             <span class="float-left">Voir les Details</span>
             <span class="float-right">
               <i class="fa fa-angle-right"></i>
             </span>
           </a>
         </div>
       </div>
       <div class="col-xl-3 col-sm-6 mb-3">
         <div class="card dashboard text-white bg-danger o-hidden h-100">
           <div class="card-body">
             <div class="card-body-icon">
               <i class="fa fa-fw fa-check"></i>
             </div>
             <div class="mr-5"><h5>{module} Modules!</h5></div>
           </div>
           <a class="card-footer text-white clearfix small z-1" onClick={() => setMainView('course_list')} href="javascript:void(0)">
             <span class="float-left">Voir Details</span>
             <span class="float-right">
               <i class="fa fa-angle-right"></i>
             </span>
           </a>
         </div>
       </div>
       </div>
       
       <h2></h2>
       {/* <div class="box_general padding_bottom">
           <div class="header_box version_2">
               <h2><i class="fa fa-bar-chart"></i>Statistic</h2>
           </div>
        <canvas id="myAreaChart" width="100%" height="30" style={{margin:"45px 0 15px 0;"}}></canvas>
       </div> */}
     </div>

        </>
    );
}