import { Button, Dropdown,Modal,notification, Table,Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { APP_URL } from "../../../URL";
import { FullscreenExitOutlined, FullscreenOutlined,ExclamationCircleFilled,CheckCircleOutlined,LoadingOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { coursIdState, elementState, openState, subViewState } from "../../../atoms/AppAtome";
import ModificationChap from "./subviews/ModificationChap";
import ChapitreList from "./subviews/ChapitreList";
import ModuleList from "./subviews/ModuleList";
import PromosList from "./subviews/PromosList";
import ConfirmDeleteCourse from "./subviews/ConfirmDeleteCourse";

const { confirm } = Modal;
let change = false;
const showConfirmPublish = (id) => {

  const token = document.cookie
  .split('; ')
  .find(row => row.startsWith('seduc_token='))
  .split('=')[1];

  confirm({
    title: 'Vous êtes sur le point de publier un cours',
    icon: <ExclamationCircleFilled />,
    content: 'En publiant le cours, tous les visiteurs et étudiants pourront le voir et le consulter. Vous devez de préférence publier le cours une fois terminé. Voulez-vous le publier maintenant ?',
    onOk() {
      console.log('OK',id);
      axios.get(`${APP_URL}profs/turn-to-read-course/${id}/true`,{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
          },
        }).then((response) => {

        notification.open({
          message:(
             <div style={{ color: 'white' }}>
               Publier avec success
             </div>
            ),
          description: '',
          placement:'bottomLeft', 
          icon: <CheckCircleOutlined />,
          style: {
              backgroundColor: 'green', // Couleur de fond personnalisée
              color:'white',
              // Autres styles personnalisés...
            },
        })
        change = true;
      }).catch((error) => console.log(error));
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};

const showConfirmUnPublish = (id) => {

  const token = document.cookie
  .split('; ')
  .find(row => row.startsWith('seduc_token='))
  .split('=')[1];

  confirm({
    title: 'Vous êtes sur le point de dépublier un cours',
    icon: <ExclamationCircleFilled />,
    content: 'En dépubliant le cours, tous les visiteurs et étudiants ne pourront plus le voir et le consulter même si leur abonnement sont encore en cours. Vous devez de préférence dépublier le cours si vous voulez apporter une modification global et que plus aucun étudiant n\'est encore abonnée dessus. Voulez-vous le dépublier maintenant ?',
    onOk() {
      console.log('OK',id);
      axios.get(`${APP_URL}profs/turn-to-read-course/${id}/0`,{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
          },
        }).then((response) => {

        notification.open({
          message:(
             <div style={{ color: 'white' }}>
               dépublier avec success
             </div>
            ),
          descriptionf: '',
          placement:'bottomLeft', 
          icon: <CheckCircleOutlined />,
          style: {
              backgroundColor: 'green', // Couleur de fond personnalisée
              color:'white',
              // Autres styles personnalisés...
            },
        })

      }).catch((error) => console.log(error));
    },
    onCancel() {
      console.log('Cancel');
    },
  });
};

export default function CourseList(){

  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useRecoilState(openState);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const [loadingModalContent, setLoadingModalContent] = useState(true);
  const [cours, setCours] = useState({}); // pour contenir temporairement le cours

  const [element,setElement] = useRecoilState(elementState);
  const [subView, setSubview] = useRecoilState(subViewState);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
    };
    
    const config = {
      headers: headers,
      };


  useEffect(() => {
     
      axios.get(APP_URL+'profs/course-ist',config).then((response) => {
          setData(response.data.datas);
          console.log('le serveur retourne',response.data);
          setLoading(false);
      }).catch((error) => {
          console.log(error)
          setLoading(false);
      });

  },[loading,subView]);

    const onChange = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
    };

   

// items pour les options 
// const items = ;


const columns = [
  {
    title: 'Nom',
    dataIndex: 'nom',
    showSorterTooltip: {
      target: 'full-header',
    },
   
   
  },
  {
    title: 'Type',
    dataIndex: 'type',
    showSorterTooltip: {
      target: 'full-header',
    },
   
  },
  {
    title: 'Description',
    dataIndex: 'description',
    showSorterTooltip: {
      target: 'full-header',
    },
   
  },
  {
    title: 'Prix initial (fcfa)',
    dataIndex: 'prix_initial',
    showSorterTooltip: {
      target: 'full-header',
    },
   
  },
  {
    title: 'Prix de reduction (fcfa)',
    dataIndex: 'prix_reduc',
    showSorterTooltip: {
      target: 'full-header',
    },
   
  },
  {
    title: 'Etat',
    dataIndex: 'etat',
    showSorterTooltip: {
      target: 'full-header',
    },
    render:(value, record) => (
      <>
        {record.etat? <>
           
            <Button onClick={() => showConfirmUnPublish(record.id)}  danger><FullscreenOutlined /> &nbsp; De-publier</Button>
        </>:<>
           
           <Button onClick={() => showConfirmPublish(record.id)} type="primary"> <FullscreenExitOutlined /> &nbsp; Publier</Button>
        </>}
      </>
    ),
   
  },
  {
    title: 'Duree',
    dataIndex: 'duree',
    showSorterTooltip: {
      target: 'full-header',
    },
   
  },
  {
    title: 'Niveau',
    dataIndex: 'niveau',
    showSorterTooltip: {
      target: 'full-header',
    },
  
  },
  {
    title: 'Langue',
    dataIndex: 'langue',
    showSorterTooltip: {
      target: 'full-header',
    },
   
  },
  {
    title: 'Options',
    dataIndex: 'option',
    render: (value, record) => (<>

<Dropdown
      menu={{
        items : [
          {
            key: '1',
            label: (
              <a rel="noopener noreferrer" onClick={() => {
                showModal(); // on affiche le modal
                setLoadingModalContent(false); // on fais charger elements du modal
                setElement('modification'); // on precise que l'element c'est une simple modification
                setCours(record); // on passe l'objet de type cours
                 
              }} href="javascript:void(0)">
                modifier le cours
              </a>
            ),
          },
          {
            key: '2',
            label: (
              <a rel="noopener noreferrer" onClick={() => {
                showModal(); // on affiche le modal
                setLoadingModalContent(false); // on fais charger elements du modal
                setElement('chapitre_list'); // on precise que l'element c'est une simple modification
                setCours(record); // on passe l'objet de type cours
                 
              }} href="javascript:void(0)">
                Les chapitres du cours
              </a>
            ),
          },
          {
              key: '3',
              label: (
                <a rel="noopener noreferrer"onClick={() => {
                  showModal(); // on affiche le modal
                  setLoadingModalContent(false); // on fais charger elements du modal
                  setElement('promos_list'); // on precise que l'element c'est une simple modification
                  setCours(record); // on passe l'objet de type cours
                   
                }} style={{color:'orange'}} href="javascript:void(0)">
                  promotions du cours
                </a>
              ),
            },
            {
              key: '4',
              label: (
                <a rel="noopener noreferrer" onClick={() => {
                  showModal(); // on affiche le modal
                  setLoadingModalContent(false); // on fais charger elements du modal
                  setElement('modules_list'); // on precise que l'element c'est une simple modification
                  setCours(record); // on passe l'objet de type cours
                   
                }} style={{color:'brown'}} href="javascript:void(0)">
                  Les modules de ce cours
                </a>
              ),
            },
          {
            key: '5',
            label: (
              <a rel="noopener noreferrer" onClick={() => {
                showModal(); // on affiche le modal
                setLoadingModalContent(false); // on fais charger elements du modal
                setElement('delete_course'); // on precise que l'element c'est une simple modification
                setCours(record); // on passe l'objet de type cours
                 
              }} style={{color:'red'}} href="javascript:void(0)">
                Supprimer ce cours 
              </a>
            ),
          },
        
        ]
      }}
      placement="left"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <Button type="primary">...</Button>
</Dropdown>

    </>)
  },
 
];


    return (
        <>
  <div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">Liste des cours</li>
     </ol>
 <h3>Liste de vos cours &nbsp;&nbsp; 
  {loading == true && 
 <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 24,
        }}
        spin
      />
    }
  />
}
 </h3>
<div className="container-fluid" style={{background:'white', padding:'2%'}}>
 <Table
    bordered={4}
    columns={columns}
    dataSource={data}
    onChange={onChange}
    showSorterTooltip={{
      target: 'sorter-icon',
    }}
  />
</div>

     <Modal
        title=""
        open={open}
        footer={null}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={700}
      >
        
     
         {element === 'modification' && <ModificationChap cours={cours} />}
         {element === 'chapitre_list' && <ChapitreList cours={cours} />}
         {element === 'modules_list' && <ModuleList cours={cours} />}
         {element === 'promos_list' && <PromosList cours={cours} />}
         {element === 'delete_course' && <ConfirmDeleteCourse cours={cours}  />}

      </Modal>

<br /><br /><br />
</div>
           
        </>
    );
}