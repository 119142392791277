import { useRecoilState } from "recoil";
import { coursDetailsIdState, mainViewState } from "../../../atoms/AppAtome";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { APP_URL } from "../../../URL";
import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { Avatar, Card, Spin } from "antd";
const { Meta } = Card;

export default function LearnView(){

    const [coursDetailId, setCoursDetailId] = useRecoilState(coursDetailsIdState)
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [loading, setLoading] = useState(true);
    const [cours, setCours] = useState([]);

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    useEffect(() => {

        axios.get(APP_URL+'students/get-my-course',config).then((response) => {

            setLoading(false);
            setCours(response.data.datas)

        }).catch((error) => {

            console.log(error);
        });

    },[]);

    return (
        <>
 <div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">Mes cours</li>
     </ol>
    
     <h4> Liste de mes cours </h4>

     {loading == true && <>
            <center>
            <br />
            <br />
 <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 30,
        }}
        spin
      />
    }
  />
            </center>
        </>}

        <div class="row">

        {cours.map((item) => (
            <>

     <div class="col-xl-5 col-sm-6 mb-5">
            <div class="card dashboard text-white bg-primary o-hidden h-100">
<Card
  onClick={() => { setCoursDetailId(item.id); setMainView('read_my_course') }}
    style={{
      width: '100%',
    }}
    cover={

      <>
      {item.couverture !== null?<>

        <img
        alt="example"
        src={`${item.couverture}`}
       />

      </>:<>

      <img
        alt="example"
        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
      />

      </>}
      
      </>
    }
    actions={[
     
         <SendOutlined  key="sendout" />

    ]}
  >


    <Meta
      avatar={<Avatar src={`${item.profil_auteur}`} />}
      title={item.nom}
      description="Selectionner pour vous"
    />

  </Card>
            </div>
     </div>

        </>))} 

        </div>

 </div>
        </>
    );
}