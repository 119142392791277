import { Button, Table, message, notification } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { APP_URL } from '../../../URL';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import {coursIdState, mainViewState } from '../../../atoms/AppAtome';
import { CheckCircleOutlined } from '@ant-design/icons';

let tab = [];

export default function CreateCourse(){
    
  const [bookData, setBookData] = useState({
    nom: '',
    type:'Text et Video',
    description:'',
    prix_initial:'',
    prix_reduc:'',
    duree:'',
    extension:'min',
    mode:'Gratuit',
    categories: tab,
    niveau:'Debutant',
    langue:'Français',
    certification:'',
    url_course:''
  });

  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

  const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };
    
    const config = {
      headers: headers,
      };

  const [loading, setLoading] = useState(false);
  const [categories, setCategorie] = useState([]);
  const [mainView, setMainView] = useRecoilState(mainViewState);
  const [coursId, setCourId] = useRecoilState(coursIdState);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    axios.get(APP_URL+'profs/categorie-list',config).then((response) => {
      setCategorie(response.data.data);
    }).catch((error) => {
        console.log(error);
    });

  },[]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

  };

 
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('datas send',bookData);

    setLoading(true);

    axios.post(APP_URL + 'profs/create-course',bookData,config).then((response) => {

      message.success(response.data.message);
    
      setCourId(response.data.datas.id);

      localStorage.setItem('coursId',response.data.datas.id);

      // console.log('le courr',response.data);
      setLoading(false);

      setMainView('add_cover_course');
      
      notification.open({
        message:(
           <div style={{ color: 'white' }}>
             Enregistrer avec success
           </div>
          ),
        description: '',
        placement:'bottomLeft', 
        icon: <CheckCircleOutlined />,
        style: {
            backgroundColor: 'green', // Couleur de fond personnalisée
            color:'white',
            // Autres styles personnalisés...
          },
      })

    }).catch((error) => {
      setLoading(false);
      notification.open({
        message:(
           <div style={{ color: 'white' }}>
             Une erreur est survenue
           </div>
          ),
        description: '',
        placement:'bottomLeft', 
        icon: <CheckCircleOutlined />,
        style: {
            backgroundColor: 'red', // Couleur de fond personnalisée
            color:'white',
            // Autres styles personnalisés...
          },
      })
    })

  }
 
    return (
        <>
<div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">Creer un cours</li>
     </ol>
    
    <h3>Creer un nouveau cours</h3>

     <form onSubmit={handleSubmit} style={{background:'white', padding:'2%'}}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Titre du cours
            </label>
            <input
              type="text"
              className="form-control"
              id="nom"
              name="nom"
              value={bookData.nom}
              onChange={handleChange}
              required
            />
          </div>
    
          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Address de la Video d'introduction
            </label>
            <input
              type="text"
              className="form-control"
              id="url_course"
              name="url_course"
              value={bookData.url_course}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Type de contenu du cours
            </label>
            <select className='form-control' name='type'>
                <option value={'Texte'}>Texte</option>
                <option value={'Video'}>Video</option>
                <option value={'Texte et video'}>Texte et video</option>
            </select>
           
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Prix initial du cours
            </label>
            <input
              type="number"
              className="form-control"
              id="prix_initial"
              name="prix_initial"
              value={bookData.prix_initial}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Prix avec reduction du cours
            </label>
            <input
              type="number"
              className="form-control"
              id="prix_reduc"
              name="prix_reduc"
              value={bookData.prix_reduc}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Duree du cours
            </label>
            <input
              type="number"
              className="form-control"
              id="duree"
              name="duree"
              value={bookData.duree}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Extension de la duree du cours
            </label>
           <select className='form-control' onChange={handleChange} required name='extension'>
              <option value={'s'}>s</option>
              <option value={'min'}>min</option>
              <option value={'h'}>h</option>
           </select>
          </div>

           <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Mode du cours
            </label>
           <select className='form-control' onChange={handleChange} required name='mode'>
              <option value={'Gratuit'}>Gratuit</option>
              <option value={'Payant'}>Payant</option>
           </select>
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Niveau de contenu du cours
            </label>
            <select className='form-control'  onChange={handleChange} name='niveau'>
                <option value={'Debutant'}>Debutant</option>
                <option value={'Expert'}>Expert</option>
                <option value={'Moyen'}>Moyen</option>
            </select>
           
          </div>

          <div className="col-md-6 mb-3">
            <label htmlFor="titre" className="form-label">
              Langue du cours
            </label>
            <select className='form-control'  onChange={handleChange} name='langue'>
                <option value={'Français'}>Français</option>
                <option value={'Anglais'}>Anglais</option>
                <option value={'Autre'}>Autre</option>
            </select>
           
          </div>

          <div className="col-md-12 mb-12">
            <label htmlFor="cat" className="form-label">
              Choisie les categories de ce cours
            </label>
           <div>
               {categories.map((item) => (
                <>
                  <input type='checkbox' htmlFor={item.id} name='categorie' onClick={() => { tab.push(item.id) }} className='form-control'/>
                  <label htmlFor={item.id}>{item.nom}</label>
                </>
               ))}
           </div>
           
          </div>

          <div className="col-md-12 mb-12">
            <label htmlFor="titre" className="form-label">
              Description de la formation
            </label>
           <textarea name='description' value={bookData.description}  onChange={handleChange} className='form-control'></textarea>
           
          </div>

          
          <div className="col-md-12 mb-12">
          <br /><br/>
            <Button type='primary'  htmlType='submit' loading={loading}>Enregistre le nouveau cours</Button>
         
          </div>

      </div>

     </form>
     <br /><br /><br />
  </div>
        </>
    );
}